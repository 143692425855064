import AddIcon from '@material-ui/icons/AddCircleOutline';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import {
  GridPagination,
  GRID_ACTIONS,
  IAPIGridRequest,
  UIStore,
  Utilities,
  ViewPermission,
  SearchStore,
  ISelectOption,
} from '@wings-shared/core';
import { agGridUtilities, useAgGrid, useGridState } from '@wings-shared/custom-ag-grid';
import { ISearchHeaderRef, SearchHeaderV2 } from '@wings-shared/form-controls';
import { CustomLinkButton, SidebarStore } from '@wings-shared/layout';
import { AirportModel, AuditHistory, baseApiPath } from '@wings/shared';
import { GridOptions, FilterChangedEvent, ICellEditorParams } from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import React, { FC, ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  AirportHoursModel,
  AirportHoursStore,
  AirportModuleSecurity,
  AirportSettingsStore,
  airportSidebarOptions,
  AIRPORT_AUDIT_MODULES,
  AIRPORT_HOUR_FILTERS,
} from '../Shared';
import { useStyles } from './AirportHours.styles';
import { airportHoursGridFilters } from './fields';
import { useUnsubscribe } from '@wings-shared/hooks';
import { CommonAirportHoursGridV2 } from './Components';

interface Props {
  airportHoursStore?: AirportHoursStore;
  airportSettingsStore?: AirportSettingsStore;
  sidebarStore?: typeof SidebarStore;
}

const AirportHours: FC<Props> = ({ airportHoursStore, airportSettingsStore, sidebarStore }: Props) => {
  const classes = useStyles();
  const gridState = useGridState();
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const agGrid = useAgGrid<AIRPORT_HOUR_FILTERS, AirportHoursModel>(airportHoursGridFilters, gridState);
  const unsubscribe = useUnsubscribe();
  const _airportHoursStore = airportHoursStore as AirportHoursStore;
  const _airportSettingsStore = airportSettingsStore as AirportSettingsStore;
  const _sidebarStore = sidebarStore as typeof SidebarStore;
  const [ entityOptions, setEntityOptions ] = useState([]);
  const isICAOFilter = Utilities.isEqual(searchHeaderRef.current?.selectedOption, AIRPORT_HOUR_FILTERS.ICAO);

  /* istanbul ignore next */
  // Load Data on Mount
  useEffect(() => {
    _sidebarStore?.setNavLinks(airportSidebarOptions(true), '/airports');
    // Restore Search Result based on available history
    const searchData = SearchStore.searchData.get(location.pathname);
    if (searchData) {
      gridState.setPagination(searchData.pagination);
      searchHeaderRef.current?.setupDefaultFilters(searchData);
      SearchStore.clearSearchData(location.pathname);
      return;
    }
    loadAirportHours();
    agGrid.filtersApi.onAdvanceFilterChange$.subscribe(() => loadAirportHours());
  }, []);

  useEffect(() => {
    loadEntityOptions();
  }, [ searchHeaderRef.current?.selectedOption, searchHeaderRef.current?.searchValue ]);

  /* istanbul ignore next */
  const _searchFilters = (): IAPIGridRequest => {
    const _selectedOption = searchHeaderRef.current?.selectedOption;
    const searchChips: any = searchHeaderRef.current?.getFilters().chipValue?.valueOf();
    const _searchValue = isICAOFilter
      ? searchHeaderRef.current?.searchValue
      : searchChips.map(x =>
        Utilities.isEqual(_selectedOption, AIRPORT_HOUR_FILTERS.AIRPORT_CODE) ? x.value : x.label
      );

    if (!_searchValue) {
      return {};
    }
    return {
      ...agGrid.filtersApi.getSearchFilters(_searchValue, _selectedOption),
    };
  };

  // load dropdown options for search header select control
  /* istanbul ignore next */
  const loadEntityOptions = () => {
    let observableOf;

    switch (searchHeaderRef.current?.selectedOption) {
      case AIRPORT_HOUR_FILTERS.AIRPORT_HOURS_TYPE:
        if (_airportSettingsStore.airportHourTypes) {
          observableOf = _airportSettingsStore.loadAirportHourTypes();
        }
        break;

      case AIRPORT_HOUR_FILTERS.AIRPORT_HOURS_SUB_TYPE:
        if (_airportSettingsStore.airportHourSubTypes) {
          observableOf = _airportSettingsStore.loadAirportHourSubTypes();
        }
        break;

      case AIRPORT_HOUR_FILTERS.AIRPORT_CODE:
        const _searchValue = searchHeaderRef.current?.searchValue;
        if (!_searchValue) {
          setEntityOptions([]);
          return;
        }
        observableOf = _airportHoursStore.searchWingsAirportsByCode(_searchValue, {
          excludeRetail: true,
          includeInactive: true,
        });
        break;
      default:
        break;
    }
    if (observableOf) {
      UIStore.setPageLoader(true);
      observableOf
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(response => setEntityOptions(response));
    }
  };

  /* istanbul ignore next */
  const loadAirportHours = (pageRequest?: IAPIGridRequest): void => {
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...pageRequest,
      ..._searchFilters(),
      ...agGrid.filtersApi.gridSortFilters(),
      ...agGrid.filtersApi.getAdvancedSearchFilters(),
    };

    UIStore.setPageLoader(true);
    _airportHoursStore
      .loadAirportHours(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: response => {
          gridState.setGridData(response.results);
          gridState.setPagination(new GridPagination({ ...response }));
          agGrid.filtersApi.gridAdvancedSearchFilterApplied();
        },
      });
  };

  const gridActions = (gridAction: GRID_ACTIONS, rowIndex: number): void => {
    if (rowIndex === null) {
      return;
    }
    if (gridAction === GRID_ACTIONS.AUDIT) {
      const model: AirportHoursModel = agGrid._getTableItem(rowIndex);
      ModalStore.open(
        <AuditHistory
          title={model.airport?.label || model.icao}
          entityId={model.id}
          entityType={AIRPORT_AUDIT_MODULES.AIRPORT_HOURS}
          baseUrl={baseApiPath.airports}
        />
      );
    }
  };

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: { onDropDownChange: () => gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi)) },
      columnDefs: [],
      isEditable: false,
      gridActionProps: {
        tooltip: 'Airport Hours',
        getDisabledState: () => gridState.hasError,
      },
    });

    return {
      ...baseOptions,
      pagination: false,
      isExternalFilterPresent: () => false,
      onFilterChanged: (events: FilterChangedEvent) => {
        Array.from(gridState.columFilters).length === 0 && loadAirportHours();
      },
      onSortChanged: e => {
        agGrid.filtersApi.onSortChanged(e);
        loadAirportHours({ pageNumber: 1 });
      },
    };
  };

  const rightContent = (): ReactNode => {
    return (
      <ViewPermission hasPermission={AirportModuleSecurity.isEditable}>
        <CustomLinkButton
          variant="contained"
          startIcon={<AddIcon />}
          to="/airports/airport-hours/new"
          title="Add Hours"
        />
      </ViewPermission>
    );
  };

  return (
    <>
      <SearchHeaderV2
        ref={searchHeaderRef as RefObject<ISearchHeaderRef>}
        onExpandCollapse={agGrid.autoSizeColumns}
        selectInputs={[ agGridUtilities.createSelectOption(AIRPORT_HOUR_FILTERS, AIRPORT_HOUR_FILTERS.ICAO) ]}
        isChipInputControl={!isICAOFilter}
        chipInputProps={{
          options: isICAOFilter ? [] : entityOptions,
          allowOnlySingleSelect: false,
          getChipLabel: option => (option as AirportModel)?.displayCode || option.label,
          getOptionLabel: option => (option as AirportModel)?.displayCode || option.label,
        }}
        onResetFilterClick={() => {
          agGrid.cancelEditing(0);
          agGrid.filtersApi.resetColumnFilters();
        }}
        rightContent={rightContent}
        onFilterChange={isInitEvent =>
          loadAirportHours({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 })
        }
        isLoading={UIStore.pageLoading}
        disableControls={Boolean(Array.from(gridState.columFilters).length)}
      />
      <CommonAirportHoursGridV2
        rowData={gridState.data}
        auditFields={agGrid.auditFields(gridState.isRowEditing)}
        gridOptions={gridOptions()}
        serverPagination={true}
        paginationData={gridState.pagination}
        onPaginationChange={loadAirportHours}
        onAction={gridActions}
        nameSearchFilterParams={agGrid.filtersApi.getAdvanceFilterParams}
      />
    </>
  );
};

export default inject('airportHoursStore', 'airportSettingsStore', 'sidebarStore')(observer(AirportHours));
