
import { IAPIFilterDictionary } from '@wings-shared/core';
import { AIRPORT_MAPPING_FILTERS } from '../Shared';

export const gridFilters: IAPIFilterDictionary<AIRPORT_MAPPING_FILTERS>[] = [
  {
    columnId: 'icao',
    apiPropertyName: 'ICAOCode.Code',
    uiFilterType: AIRPORT_MAPPING_FILTERS.ICAO,
  },
  {
    columnId: 'uwaCode',
    apiPropertyName: 'UWACode',
    uiFilterType: AIRPORT_MAPPING_FILTERS.UWA,
  },
  {
    columnId: 'faaCode',
    apiPropertyName: 'FAACode',
    uiFilterType: AIRPORT_MAPPING_FILTERS.FAA,
  },
  {
    columnId: 'regionalCode',
    apiPropertyName: 'RegionalCode',
    uiFilterType: AIRPORT_MAPPING_FILTERS.Regional,
  },
  {
    columnId: 'navblue',
    apiPropertyName: 'AirportFlightPlanInfo.APGCode',
    uiFilterType: AIRPORT_MAPPING_FILTERS.APG,
  },
  {
    columnId: 'apg',
    apiPropertyName: 'AirportFlightPlanInfo.NavBlueCode',
    uiFilterType: AIRPORT_MAPPING_FILTERS.NAVBLUE,
  },
];
