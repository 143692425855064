import AddIcon from '@material-ui/icons/AddCircleOutline';
import { GRID_ACTIONS, ISelectOption, UIStore, Utilities, ViewPermission } from '@wings-shared/core';
import { CustomAgGridReact, useAgGrid, useGridState } from '@wings-shared/custom-ag-grid';
import { ColDef, GridOptions, ICellEditorParams, GridReadyEvent } from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { AirportModel, AirportSettingsStore, CustomsNoteModel, useAirportModuleSecurity } from '../../../../../Shared';
import { useStyles } from './CappsNotesGrid.styles';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { EDITOR_TYPES } from '@wings-shared/form-controls';
import { typeCodeOptions } from '../CustomDetailsInfo.fields';

interface Props {
  selectedAirport: AirportModel;
  airportSettingsStore?: AirportSettingsStore;
  isEditable: boolean;
  customsNotes: CustomsNoteModel[];
  onDataUpdate: (gridName: string, gridData: CustomsNoteModel[]) => void;
  isRowEditing: (isEditing: boolean) => void;
}

const CappsNotesGrid: FC<Props> = ({ ...props }) => {
  const gridState = useGridState();
  const classes = useStyles();
  const agGrid = useAgGrid<'', CustomsNoteModel>([], gridState);
  const _airportSettingStore = props.airportSettingsStore as AirportSettingsStore;
  const { isGRSUser, isEditable } = useAirportModuleSecurity();
  const _disabled =
    gridState.isRowEditing || UIStore.pageLoading || !props.selectedAirport?.isActive || !props.isEditable;

  /* istanbul ignore next */
  useEffect(() => {
    agGrid.setColumnVisible('actionRenderer', props.isEditable);
    gridState.setGridData(props.customsNotes);
  }, [ props.customsNotes, props.isEditable ]);

  const onInputChange = ({ colDef, data }: ICellEditorParams, value: string): void => {
    gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi));
  };

  const onDropDownChange = ({ colDef, data }: ICellEditorParams, value: ISelectOption): void => {
    gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi));
  };

  /* istanbul ignore next */
  const addNoteType = (): void => {
    agGrid.setColumnVisible('actionRenderer', true);
    agGrid.addNewItems([ new CustomsNoteModel({ id: 0 }) ], { startEditing: false, colKey: 'noteType' });
    gridState.setHasError(true);
  };

  const updateTableData = (): void => {
    gridState.gridApi.stopEditing();
    const data = agGrid._getAllTableRows();
    gridState.setGridData(data);
    props.onDataUpdate('notes', data);
    props.isRowEditing(false);
  };

  const gridActions = (gridAction: GRID_ACTIONS, rowIndex: number): void => {
    if (rowIndex === null) {
      return;
    }

    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        agGrid._startEditingCell(rowIndex, columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        updateTableData();
        props.isRowEditing(false);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        agGrid.cancelEditing(rowIndex);
        props.isRowEditing(false);
        break;
    }
  };

  const columnDefs: ColDef[] = [
    {
      headerName: 'Note Type',
      field: 'noteType',
      cellEditor: 'customAutoComplete',
      valueFormatter: ({ value }) => value?.label || '',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Select Note Type',
        getAutoCompleteOptions: () => _airportSettingStore.noteTypes,
      },
    },
    {
      headerName: 'Type Code',
      field: 'typeCode',
      cellEditor: 'customAutoComplete',
      valueFormatter: ({ value }) => value?.label || '',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Select Type Code',
        getAutoCompleteOptions: () => typeCodeOptions,
      },
    },
    {
      headerName: 'Notes',
      field: 'notes',
      cellRenderer: 'customTextAreaEditor',
      cellRendererParams: {
        readOnly: true,
        editorType: EDITOR_TYPES.RICH_TEXT_EDITOR,
      },
      cellEditor: 'customTextAreaEditor',
      cellEditorParams: {
        rules: 'required|string|max:4000',
      },
    },
    {
      ...agGrid.actionColumn({
        headerName: 'Action',
        minWidth: 150,
      }),
    },
  ];

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: { onInputChange, onDropDownChange },
      columnDefs,
      isEditable: isEditable || isGRSUser,
      gridActionProps: {
        showDeleteButton: false,
        getDisabledState: () => gridState.hasError,
        onAction: gridActions,
      },
    });

    return {
      ...baseOptions,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
      },
      suppressClickEdit: true,
      onGridReady: (param: GridReadyEvent) => {
        agGrid.onGridReady(param);
        agGrid.setColumnVisible('actionRenderer', props.isEditable);
      },
      onRowEditingStarted: params => {
        agGrid.onRowEditingStarted(params);
        props.isRowEditing(true);
        _airportSettingStore?.loadNoteTypes().subscribe();
      },
    };
  };

  return (
    <>
      <div className={classes.addButtonContainer}>
        <ViewPermission hasPermission={props.isEditable}>
          <PrimaryButton variant="contained" startIcon={<AddIcon />} disabled={_disabled} onClick={addNoteType}>
            Add Note
          </PrimaryButton>
        </ViewPermission>
      </div>
      <div className={classes.gridWrapper}>
        <CustomAgGridReact
          rowData={gridState.data}
          gridOptions={gridOptions()}
          isRowEditing={gridState.isRowEditing}
          hidePagination={true}
        />
      </div>
    </>
  );
};

export default inject('airportSettingsStore')(observer(CappsNotesGrid));
