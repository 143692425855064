import { HoursTimeModel } from '@wings-shared/scheduler';
import { AirportHoursModel, CONDITION_EDITOR } from '../../../../Shared';
import moment from 'moment';

export class AirportHoursGridHelper {
  // Reset to Default on No Schedule and Continues
  /* istanbul ignore next */
  public resetToDefault(instance, data: AirportHoursModel): void {
    instance.getComponentInstance('schedule.includeHoliday').setValue(false);
    instance.getComponentInstance('schedule.startDate').setValue('');
    instance.getComponentInstance('schedule.endDate').setValue('');
    instance
      .getComponentInstance('schedule.startTime')
      .setValue(new HoursTimeModel({ id: data.schedule?.startTime.id }));
    instance.getComponentInstance('schedule.endTime').setValue(new HoursTimeModel({ id: data.schedule?.endTime.id }));
    instance.getComponentInstance('schedule.is24Hours').setValue(false);
    instance.getComponentInstance('schedule.stddstType').setValue(false);
    instance.getComponentInstance('schedule.patternedRecurrence.recurrencePattern.daysOfWeeks').setValue([]);
  }

  public getDateTime(hours: number, mins: number): string {
    return moment()
      .utc()
      .hour(hours)
      .minutes(mins)
      .seconds(0)
      .format();
  }
}

export const integerFields = [
  CONDITION_EDITOR.DECIBEL,
  CONDITION_EDITOR.WEIGHT,
  CONDITION_EDITOR.WEIGHT_MTOW_METRICTONS,
  CONDITION_EDITOR.WEIGHT_MTOW_LBS,
  CONDITION_EDITOR.WINGSPAN,
  CONDITION_EDITOR.SEATING_CONFIGURATION,
  CONDITION_EDITOR.PAX_SEAT_CAPACITY,
  CONDITION_EDITOR.CREW_SEAT_CAPACITY,
  CONDITION_EDITOR.TOTAL_SEAT_CAPACITY,
  CONDITION_EDITOR.EPN_DB,
].map(x => x.toString());

export const booleanFields = [
  CONDITION_EDITOR.ARRIVAL,
  CONDITION_EDITOR.DEPARTURE,
  CONDITION_EDITOR.USE_AS_ALTERNATE,
  CONDITION_EDITOR.EVENT,
];

const airportHoursGridHelper = new AirportHoursGridHelper();
export default airportHoursGridHelper;
