/* istanbul ignore next */
export const apiUrls = {
  airports: 'api/Airport',
  updateAirportUWACode: airportId => `/api/Airport/UpdateAirportUWACode/${airportId}`,
  updateAirportICAOCode: airportId => `/api/Airport/UpdateAirportICAOCode/${airportId}`,
  validateAirport: 'api/Airport/ValidateAirport',
  updateAirportRegionalCode: airportId => `/api/Airport/UpdateAirportRegionalCode/${airportId}`,
  airportManagement: airportId => `api/Airport/${airportId}/airportManagement`,
  airportFlightPlanInfo: airportId => `api/Airport/${airportId}/airportflightplaninfo`,
  airportOperationalInfo: airportId => `api/Airport/${airportId}/airportoperationalinfo`,
  updateAirportOperationalInfo: (airportId, id) => `api/Airport/${airportId}/airportoperationalinfo/${id}`,
  airportSecurity: airportId => `api/Airport/${airportId}/AirportSecurity`,
  upsertAirportDiagram: 'api/Airport/airportdiagram',
  importAirport: 'api/Airport/Import',
  airportHours: 'api/AirportHours',
  airportHoursById: airportHoursId => `api/AirportHours/${airportHoursId}`,
  airportHoursInactive: airportHoursId => `api/AirportHours/${airportHoursId}/InActive`,
  conditionType: 'api/ConditionType',
  airportHoursType: 'api/AirportHoursType',
  airportHoursSubtype: 'api/airportHoursSubtype',
  airportTimezones: airportId => `api/Airport/${airportId}/timezones`,
  timeZoneById: timeZoneId => `api/timeZone/${timeZoneId}`,
  accessLevel: 'api/AccessLevel',
  sourceType: 'api/SourceType',
  conditionalOperator: 'api/ConditionalOperator',
  referenceData: 'api/referenceData',
  airportHoursRemark: 'api/AirportHoursRemark',
  stddstType: 'api/STDDSTType',
  scheduleType: 'api/scheduleType',
  airportType: 'api/airportType',
  airportFacilityType: 'api/airportFacilityType',
  distanceUOM: 'api/distanceUOM',
  airportDirection: 'api/airportDirection',
  audit: 'api/AuditHistory',
  airportUsageType: 'api/airportUsageType',
  airportFacilityAccessLevel: 'api/airportFacilityAccessLevel',
  ICAOCode: 'api/ICAOCode',
  airportDataSource: 'api/AirportDataSource',
  ICAOCodeAuditHistory: 'api/ICAOCode/history',
  militaryUseType: 'api/MilitaryUseType',
  airportHoursBuffer: 'api/AirportHoursBuffer',
  runwaySurfaceType: 'api/RunwaySurfaceType',
  runwayCondition: 'api/RunwayCondition',
  runwaySurfaceTreatment: 'api/RunwaySurfaceTreatment',
  runwayLightType: 'api/RunwayLightType',
  runwayRVR: 'api/RunwayRVR',
  runwayApproachLight: 'api/RunwayApproachLight',
  runwayVGSI: 'api/RunwayVGSI',
  FAAImportProcess: 'api/FAAImportProcess',
  FAAImportStaging: 'api/FAAImportStaging',
  FAAMerge: 'api/FAAImportStaging/FAAMerge',
  FAAMergeByAirport: 'api/FAAImportStaging/FAAMergeByAirport',
  airportMapping: '/FlightPlanning/v2/airports/mappings',
  weatherReportingSystem: 'api/WeatherReportingSystem',
  airportCategory: 'api/AirportCategory',
  runwayNavaids: 'api/RunwayNavaids',
  runwayApproachType: 'api/RunwayILSApproachType',
  runwayUsageType: 'api/RunwayUsageType',
  airportAWOSType: 'api/AWOSType',
  airportClassCode: 'api/ClassCode',
  airportCertificateCode: 'api/CertificateCode',
  airportServiceCode: 'api/ServiceCode',
  updateAirportFlightPlanInfo: (airportId, id) => `api/Airport/${airportId}/airportflightplaninfo/${id}`,
  airportFrequencyRunway: (airportFrequencyId: number) =>
    `api/AirportFrequency/${airportFrequencyId}/AirportFrequencyRunway`,
  runwayByAirport: (airportId: number) => `/api/airport/${airportId}/Runway`,
  airportFrequency: 'api/AirportFrequency',
  airportOfEntry: 'api/AirportOfEntry',
  updateAirportStatus: airportId => `/api/Airport/${airportId}/UpdateAirportStatus`,
  frequencyType: 'api/FrequencyType',
  sector: 'api/Sector',
  airportA2gAgentProfile: 'api/Airport/airportA2gAgentProfile',
  fuelType: 'api/FuelType',
  oilType: 'api/OilType',
  areaPortAssignment: 'api/AreaPortAssignment',
  requiredInformationType: 'api/RequiredInformationType',
  customGeneralInfo: 'Customs/generalInformation',
  intlCustomsInfo: 'customs/InternationalCustomsInformation',
  customsDetail: 'customs/CustomsDetail',
  usCustomsInfo: 'customs/USCustoms',
  customsContact: 'api/CustomsContact',
  fieldOfficeOversight: 'api/FieldOfficeOversight',
  customsLocationInformation: 'api/CustomsLocationInformation',
  maxPOBOption: 'api/MaxPOBOption',
  cbpPortType: 'api/CBPPortType',
  visaTiming: 'api/VisaTiming',
  uwaAirportCode: 'api/UWAAirportCode',
  regionalAirportCode: 'api/RegionalAirportCode',
  runwayClosures: (airportId: number,runwayId:number) => `/api/airport/${airportId}/Runway/${runwayId}/runwayClosures`,
  rampSideAccess: 'api/RampSideAccess',
  rampSideAccessThirdParty: 'api/RampSideAccess3rdParty',
  rampSideAccessThirdPartyVendors: 'api/RampSideAccess3rdPartyVendor',
  securityMeasures: 'api/SecurityMeasure',
  recommendedServices: 'api/RecommendedService',
  destinationAlternateTOF: 'api/DestinationAlternateTOF',
  overtime: 'api/Overtime',
  flightType: 'api/FlightType',
  conditionTypeConfig: 'api/ConditionTypeConfig',
  noiseClassification: 'api/NoiseClassification',
  leadTimeType: 'api/CustomsLeadTimeType',
  airportHourStaging: 'api/AirportHourStaging',
  largeAircraftRestriction: 'api/LargeAircraftRestriction',
  customsContactType: 'api/CustomsContactType',
  customsContactAddressType: 'api/CustomsContactAddressType',
  overnightParking: 'api/OvernightParking',
  noteType: 'api/NoteType',
};
