import React, { ReactNode, RefObject } from 'react';
import { withStyles, IconButton, LinearProgress, Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Dialog } from '@uvgo-shared/dialog';
import { AlertStore } from '@uvgo-shared/alert';
import { PrimaryButton, SecondaryButton } from '@uvgo-shared/buttons';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import {
  AirportHoursModel,
  AirportHoursStore,
  AirportHoursTypeModel,
  AirportSettingsStore,
  ConditionModel,
  ConditionValueModel,
} from '../../../../Shared';
import { styles } from './OtOrHoursDetails.styles';
import AirportHoursGrid, { PureHoursGrid } from '../AirportHoursGrid/AirportHoursGrid';
import { takeUntil, finalize } from 'rxjs/operators';
import { AspectRatio } from '@material-ui/icons';
import { IClasses, UIStore, Utilities, UnsubscribableComponent, SettingsTypeModel } from '@wings-shared/core';

interface Props {
  classes?: IClasses;
  airportHoursModel: AirportHoursModel;
  airportHoursStore: AirportHoursStore;
  airportSettingsStore: AirportSettingsStore;
  updateGridItem?: (updatedModel: AirportHoursModel[]) => void;
}

@observer
class OtOrHoursDetails extends UnsubscribableComponent<Props, AirportHoursModel> {
  private gridRef: RefObject<PureHoursGrid> = React.createRef<PureHoursGrid>();

  componentDidMount() {
    this.createOTORRecords();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.airportHoursStore.otorAirportHours = [];
  }

  private get airportHoursStore(): AirportHoursStore {
    return this.props.airportHoursStore;
  }

  private get airportSettingsStore(): AirportSettingsStore {
    return this.props.airportSettingsStore;
  }

  private get hasError(): boolean {
    return Boolean(this.gridRef.current?.hasErrorInGrid || this.gridRef.current?.isRowEditing);
  }

  // Needs to call using REF from Parent
  /* istanbul ignore next */
  private createOTORRecords(): void {
    this.airportSettingsStore.loadOvertime().subscribe(response => {
      const conditionType = response.find(x => Utilities.isEqual(x.label, 'On Request'));

      if (!conditionType) {
        AlertStore.important('On Request not found');
        return;
      }
      const defaultModel = this.getAirportHoursTemplate(this.props.airportHoursModel, conditionType);
      // create unique cappsSequenceId for new OT/OR Hours
      const cappsSequenceIds: number[] = [
        defaultModel.cappsSequenceId,
        ...this.airportHoursStore.airportHours.map(({ cappsSequenceId }) => cappsSequenceId),
      ];
      const cappsSequenceId: number = Math.max(...cappsSequenceIds);
      this.airportHoursStore.createOTORHours(defaultModel, cappsSequenceId);
    });
  }

  // fill default values for OT/OR records before creating records
  /* istanbul ignore next */
  private getAirportHoursTemplate(
    airportHours: AirportHoursModel,
    conditionValue: SettingsTypeModel
  ): AirportHoursModel {
    const airportHoursSubType = this.airportSettingsStore.airportHourSubTypes.find(({ name }) =>
      Utilities.isEqual(name, 'operational hours')
    );
    const { conditionTypes, conditionalOperators } = this.airportSettingsStore;
    const condition = new ConditionModel({
      ...airportHours.condition,
      //conditionValues: 'On Request',
      conditionValues: [
        new ConditionValueModel({ entityValue: conditionValue.name, entityValueId: conditionValue.id }),
      ],
      conditionType: conditionTypes.find(({ name }) => Utilities.isEqual(name, 'overtime')),
      conditionalOperator: conditionalOperators.find(
        ({ operator }) => Utilities.isEqual(operator, 'Equal') || Utilities.isEqual(operator, '=')
      ),
    });
    return new AirportHoursModel({
      ...airportHours,
      id: 0,
      conditions: [ condition ],
      airportHoursSubType,
      cappsComment: 'OT/OR',
    });
  }

  /* istanbul ignore next */
  private upsertAirportHours(): void {
    const gridRows = this.gridRef.current?.gridRows || [];
    UIStore.setPageLoader(true);
    this.props.airportHoursStore
      .upsertAirportHours(gridRows.map(x => x.serialize()))
      .pipe(
        finalize(() => UIStore.setPageLoader(false)),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (response: AirportHoursModel[]) => {
          ModalStore.close();
          this.props.updateGridItem && this.props.updateGridItem(response);
        },
        error: error => AlertStore.critical(error.message),
      });
  }

  private get dialogActions(): ReactNode {
    return (
      <Box width="100%">
        {UIStore.pageLoading ? <LinearProgress /> : <></>}
        <Box display="flex" justifyContent="end" mt="5px">
          <PrimaryButton variant="contained" onClick={() => ModalStore.close()} disabled={UIStore.pageLoading}>
            Cancel
          </PrimaryButton>
          <SecondaryButton
            variant="contained"
            onClick={() => this.upsertAirportHours()}
            disabled={this.hasError || UIStore.pageLoading}
          >
            Save
          </SecondaryButton>
        </Box>
      </Box>
    );
  }

  private get title(): ReactNode {
    return (
      <div>
        {`Airport Hours - ${this.props.airportHoursModel.airport?.operationalCode}`}
        <IconButton onClick={() => this.gridRef.current?.autoSizeColumns()}>
          <AspectRatio />
        </IconButton>
      </div>
    );
  }

  public render() {
    const { classes, airportHoursModel } = this.props as Required<Props>;
    return (
      <Dialog
        open={true}
        title={this.title}
        classes={{
          paperSize: classes.modalWidth,
          header: classes.headerWrapper,
          dialogWrapper: classes.headerWrapper,
        }}
        onClose={() => ModalStore.close()}
        dialogContent={() => (
          <AirportHoursGrid
            ref={this.gridRef}
            isOtOrRecord={true}
            isEditable={true}
            rowData={this.airportHoursStore.otorAirportHours}
            airportHoursStore={this.airportHoursStore}
            airportSettingsStore={this.airportSettingsStore}
            airportHoursType={airportHoursModel.airportHoursType as AirportHoursTypeModel}
          />
        )}
        dialogActions={() => this.dialogActions}
      />
    );
  }
}
export default withStyles(styles)(OtOrHoursDetails);
