export enum SETTING_CATEGORIES {
  GENERAL = 1,
  AIRPORTS,
  AIRPORT_HOURS,
  RUNWAY,
  BULLETIN,
  OPERATIONAL_INFORMATION,
  CUSTOMS,
  SECURITY,
}

export enum SETTING_ID {
  SOURCE_TYPE = 1,
  ACCESS_LEVEL,
  DISTANCE_UOM,
  AIRPORT_TYPE,
  AIRPORT_FACILITY_TYPE,
  AIRPORT_DIRECTION,
  AIRPORT_USAGE_TYPE,
  AIRPORT_FACILITY_ACCESS_LEVEL,
  OFFICIAL_ICAO_CODE,
  AIRPORT_HOUR_TYPE,
  AIRPORT_HOUR_SUB_TYPE,
  AIRPORT_HOUR_REMARK,
  CONDITION_TYPE,
  CONDITIONAL_OPERATOR,
  SCHEDULE_TYPE,
  MILITARY_USE_TYPE,
  AIRPORT_HOURS_BUFFER,
  RUNWAY_SURFACE_TYPE,
  RUNWAY_CONDITION,
  RUNWAY_SURFACE_TREATMENT,
  RUNWAY_LIGHT_TYPE,
  RUNWAY_RVR,
  RUNWAY_APPROACH_LIGHT,
  RUNWAY_VGSI,
  AIRPORT_DATA_SOURCE,
  WEATHER_REPORTING_SYSTEM,
  AIRPORT_CATEGORY,
  RUNWAY_NAVAIDS,
  RUNWAY_APPROACH_TYPE,
  RUNWAY_USAGE_TYPE,
  AIRPORT_AWOS_TYPE,
  AIRPORT_CLASS_CODE,
  AIRPORT_CERTIFICATE_CODE,
  AIRPORT_SERVICE_CODE,
  AIRPORT_OF_ENTRY,
  FREQUENCY_TYPE,
  SECTOR,
  BULLETIN_LEVEL,
  BULLETIN_TYPE,
  BULLETIN_SOURCE,
  BULLETIN_PRIORITY,
  FUEL_TYPE,
  OIL_TYPE,
  AREA_PORT_ASSIGNMENT,
  REQUIRED_INFORMATION_TYPE,
  FIELD_OFFICE_OVERSIGHT,
  CUSTOMS_LOCATION_INFORMATION,
  MAX_POB_OPTION,
  CBP_PORT_TYPE,
  VISA_TIMING,
  UWA_CODE,
  REGIONAL_CODE,
  RAMP_SIDE_ACCESS,
  RAMP_SIDE_ACCESS_THIRD_PARTY_VENDORS,
  RAMP_SIDE_ACCESS_THIRD_PARTY,
  SECURITY_MEASURES,
  RECOMMENDED_SERVICES,
  DESTINATION_ALTERNATE_TYPE_OF_FLIGHT,
  CAPPS_CATEGORY_CODE,
  OVERTIME,
  FLIGHT_TYPE,
  CONDITION_TYPE_CONFIG,
  NOISE_CLASSIFICATION,
  LEAD_TIME_TYPE,
  LARGE_AIRCRAFT_RESTRICTION,
  CONTACT_TYPE,
  CONTACT_ADDRESS_TYPE,
  OVERNIGHT_PARKING,
  NOTE_TYPE
}
